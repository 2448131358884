// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-2-jsx": () => import("./../../../src/pages/index-2.jsx" /* webpackChunkName: "component---src-pages-index-2-jsx" */),
  "component---src-pages-index-3-jsx": () => import("./../../../src/pages/index-3.jsx" /* webpackChunkName: "component---src-pages-index-3-jsx" */),
  "component---src-pages-index-4-jsx": () => import("./../../../src/pages/index-4.jsx" /* webpackChunkName: "component---src-pages-index-4-jsx" */),
  "component---src-pages-index-5-jsx": () => import("./../../../src/pages/index-5.jsx" /* webpackChunkName: "component---src-pages-index-5-jsx" */),
  "component---src-pages-index-6-jsx": () => import("./../../../src/pages/index-6.jsx" /* webpackChunkName: "component---src-pages-index-6-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-templates-author-post-index-js": () => import("./../../../src/templates/author-post/index.js" /* webpackChunkName: "component---src-templates-author-post-index-js" */),
  "component---src-templates-categories-post-index-js": () => import("./../../../src/templates/categories-post/index.js" /* webpackChunkName: "component---src-templates-categories-post-index-js" */),
  "component---src-templates-date-post-index-js": () => import("./../../../src/templates/date-post/index.js" /* webpackChunkName: "component---src-templates-date-post-index-js" */),
  "component---src-templates-single-post-index-js": () => import("./../../../src/templates/single-post/index.js" /* webpackChunkName: "component---src-templates-single-post-index-js" */),
  "component---src-templates-tag-post-index-js": () => import("./../../../src/templates/tag-post/index.js" /* webpackChunkName: "component---src-templates-tag-post-index-js" */)
}

